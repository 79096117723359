import React from 'react';
import { ClockCircleOutlined, DollarOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';

const AdvertiseCard = ({props}) => {
  const word = props.selectedOptiontype;
  const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ scale: 1.01 }}
      transition={{ duration: 0.2 }}
      className="bg-white rounded-lg sm:rounded-xl shadow-md hover:shadow-xl p-3 sm:p-4 
        w-[95%] sm:w-[90%] md:w-4/5 lg:w-4/6 mx-auto my-2 sm:my-3 
        border border-gray-100 hover:border-blue-100 transition-all duration-300"
    >
      <div className="flex flex-col gap-2 sm:gap-3">
        {/* Top Section - Company & Location */}
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2">
          <div className="flex items-center gap-2">
            <span className='text-gray-900 text-sm sm:text-3xl font-bold truncate max-w-[200px] sm:max-w-none'>
              {capitalizeFirstLetter(props.companyName)}
            </span>
            <span className='text-gray-400 text-xs'>is hiring</span>
          </div>
          
          <div className='flex items-center gap-2 text-xs bg-gray-50 px-2 sm:px-3 py-1 rounded-full'>
            <EnvironmentOutlined className="text-red-500" />
            <span className='font-medium'>{props.city}</span>
            <span className='text-gray-300'>•</span>
            <span className='font-medium'>{props.selectedCountry}</span>
          </div>
        </div>

        {/* Middle Section - Job Title */}
        <div>
          <h2 className='text-blue-600 text-base sm:text-lg font-bold mb-2 
            hover:text-blue-700 transition-colors line-clamp-2'>
            {props.title}
          </h2>
          
          <div className='flex flex-wrap items-center gap-2 text-xs text-gray-600'>
            <div className='flex items-center gap-1 bg-gray-50 px-2 py-1 rounded-full'>
              <ClockCircleOutlined className="text-blue-500" />
              <span>{capitalizedWord}</span>
            </div>
            <div className='flex items-center gap-1 bg-gray-50 px-2 py-1 rounded-full'>
              <DollarOutlined className="text-green-500" />
              <span>{props.selectedSalary}</span>
            </div>
          </div>
        </div>

        {/* Bottom Section - Button */}
        <div className='flex justify-end'>
          <button className="w-full sm:w-auto bg-blue-50 text-blue-600 px-3 py-1.5 rounded-full 
            text-xs sm:text-sm font-medium hover:bg-blue-100 transition-colors duration-300
            hover:shadow-md active:scale-95 transform">
            View Details
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default AdvertiseCard;
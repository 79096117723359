/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Vector from '../images/new_uiuxlogo1.png';
import uiuxjobshub from '../images/uiuxjobshub_transparent-.png';
import Frame3 from '../images/Frame3.png';
import { Divider,message } from 'antd';
import { TwitterOutlined, FacebookOutlined, InstagramOutlined, LinkedinOutlined } from '@ant-design/icons';

const Footers = () => {
  const [email, setEmail] = useState('');
  
  const handleSubscribe = () => {
    if (!email.includes('@') || !email.includes('.')) {
      message.error('Please enter a valid email');
      return;
    }
    message.success('Subscribed successfully');
    setEmail('');
  };

  return (
    <footer className="bg-gray-900 text-gray-300">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Brand Column */}
          <div className="space-y-4">
            <img src={Vector} alt="UIUX Jobs Hub" className="h-8 w-auto" />
            <p className="text-sm text-gray-400">
              Connecting top UI/UX talent with innovative companies worldwide.
            </p>
            <div className="flex space-x-4">
              <a href="https://www.linkedin.com/company/uiuxjobshub/" className="text-gray-400 hover:text-blue-500 transition-colors">
                <LinkedinOutlined className="text-xl" />
              </a>
              {/* <a href="#" className="text-gray-400 hover:text-blue-500 transition-colors">
                <TwitterOutlined className="text-xl" />
              </a> */}
              <a href="https://www.linkedin.com/company/uiuxjobshub/" className="text-gray-400 hover:text-blue-500 transition-colors">
                <InstagramOutlined className="text-xl" />
              </a>
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-white font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><Link to="/terms" className="text-gray-400 hover:text-white transition-colors">About Us</Link></li>
              <li><Link to="/hire" className="text-gray-400 hover:text-white transition-colors">Post a Job</Link></li>
              <li><Link to="/" className="text-gray-400 hover:text-white transition-colors">Browse Jobs</Link></li>
            </ul>
          </div>

          {/* Legal */}
          <div>
            <h3 className="text-white font-semibold mb-4">Legal</h3>
            <ul className="space-y-2">
              <li><Link to="/privacy-policy" className="text-gray-400 hover:text-white transition-colors">Privacy Policy</Link></li>
              <li><Link to="/terms" className="text-gray-400 hover:text-white transition-colors">Terms of Service</Link></li>
              <li><Link to="/contact-us" className="text-gray-400 hover:text-white transition-colors">Contact Us</Link></li>
            </ul>
          </div>

          {/* Newsletter */}
          <div>
            <h3 className="text-white font-semibold mb-4">Newsletter</h3>
            <div className="flex flex-col space-y-3">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="bg-gray-800 text-white px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                onClick={handleSubscribe}
                className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-800 mt-12 pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-sm text-gray-400">© 2024 UIUXJobshub. All rights reserved.</p>
            <p className="text-sm text-gray-400 mt-2 md:mt-0">Made with ❤️ in India</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footers;
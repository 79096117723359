import React,{useState,useEffect} from 'react'
import ReactQuill from 'react-quill';
import {LinkOutlined, ClockCircleOutlined, DollarOutlined, FileSearchOutlined, ProfileOutlined, EnvironmentOutlined} from '@ant-design/icons';
import 'react-quill/dist/quill.snow.css'; 
import {QRCodeSVG} from 'qrcode.react';
import moment from 'moment';

import { Tooltip,Typography ,Divider} from 'antd';
const Preview = (props) => {
  console.log('props',props)
  const [userCountry, setUserCountry] = useState('');
  const [parsedContent, setParsedContent] = useState('');
  const [isOpenedByAdmin, setIsOpenedByAdmin] = useState(false);
  
  // const fetchUserCountry = async () => {
  //   try {
  //     const response = await fetch('https://geolocation-db.com/json/');
  //     const data = await response.json();
  //     console.log('data:===>', data);
  //     setUserCountry(data.country_name);
  //   } catch (error) {
  //     console.error('Error fetching user country:', error);
  //   }
  // };
  // console.log('props000000000000',props)
  useEffect(()=>{
    if(localStorage.getItem('isAdminByAdminSkshh')==='true'){
      setIsOpenedByAdmin(true);
    }
  },[])
 const {Text} = Typography;
  const handleXTweet = () => {
    const currentUrl = window.location.href;
    const tweetText = `🚀 Exciting ${props.selectedCategory} Opportunity Alert! 

🏢 ${props.companyName} is hiring a ${props.title}
💰 Compensation: ${props.selectedSalary}
🌍 Location: ${props.selectedCountry}
⭐ Type: ${props.selectedOptiontype}

🔍 Join a team that values innovation and creativity!

Apply here 👇
${currentUrl}

#UIUXJobs #Design #Hiring`;

    const encodedText = encodeURIComponent(tweetText);
    const twitterIntentUrl = `https://twitter.com/intent/tweet?text=${encodedText}`;
    window.open(twitterIntentUrl, '_blank');
  }
function calculateApplyRate(clicked_apply,clicked_viewed){
  return ((clicked_apply/clicked_viewed)*100).toFixed(2);
}
function sideComponent () {
  return (<>
     {/* <div className=" flex flex-col shadow-md   rounded-xl w-full" style={{background: 'linear-gradient(to bottom, black 18%, whitesmoke 10%,white 70%)'}}>  */}
          
          {/* <div className='w-28 h-28 mx-auto bg-white rounded-full overflow-hidden m-4'>
             <img className='circular-image' alt="comp-ima" src={props.display_url} style={{}} />
          </div> */}
          {/* <p className='mb-4 mx-auto  font-extrabold text-3xl   ' style={{color: '#333333'}} >{props.title}</p>
          <p className='mb-4 mx-auto  font-extrabold text-xl  ' style={{color: '#333333'}} onClick={() => {window.open(`${props.companywebsiteLink}`, '_blank');}}>@{props.companyName}</p>
          <div className='flex flex-wrap justify-between mx-32 '>
          <div className='text-xs  w-fit my-3 '><p className='font-bold'>🧑‍💼CATEGORY</p><p style={{backgroundColor:'black', color:'white', padding:'5px', borderRadius:'20px',width:'fit-content'}} className='generalTextmini'>{props.selectedCategory}</p></div>
          <div className='text-xs  w-fit my-3 '><p className='font-bold'>🕴️TYPE</p><p className='capitalize generalTextmini' style={{backgroundColor:'black', color:'white', padding:'5px', borderRadius:'20px',width:'fit-content'}} >{props.selectedOptiontype}</p></div>
          </div>
          <div className='flex flex-wrap justify-between mx-32 '>
          <div className='text-xs  w-fit my-3'><p className='font-bold'>💸SALARY</p><p style={{backgroundColor:'black', color:'white', padding:'5px', borderRadius:'20px',width:'fit-content'}} className='generalTextmini'>{props.selectedSalary}</p></div>
          <div className='text-xs  w-fit my-3'><p className='font-bold'>📍LOCATION</p><p style={{backgroundColor:'black', color:'white', padding:'5px', borderRadius:'20px',width:'fit-content'}} className='generalTextmini'>{props.selectedCountry}</p></div>
          </div> */}
          <button class="animate-[wiggle_1s_ease-in-out_infinite] mb-4 font-extrabold hover:text-black hover:bg-white hover:border-black  mx-auto bg-black text-white border  border-white px-4 py-2 rounded-md transition duration-300 ease-in-out" 
          onClick={() => handleActionBasedonCountry()}>
            Apply Now 📨
         </button>
         <div className=' p-2  rounded-lg mx-auto mb-4  w-full h-auto flex flex-row justify-around'>
         <Tooltip title="Shows how many times the job post has been viewed">
         <div className='flex flex-col w-fit border  border-gray-400 rounded-lg  align-middle p-3 text-center '>
          <p className='text-base font-bold'>Viewed</p>
           👀  {props.otherdata.clicked_viewed}
         </div>
         </Tooltip>

         <Tooltip title="Shows how many times the job post has been applied">
         <div className='flex flex-col w-fit border  border-gray-400 rounded-lg  align-middle p-3 text-center '>
          <p className='text-base font-bold'>Applied</p>
           👉  {props.otherdata.clicked_apply}
         </div>
         </Tooltip>
         
         <Tooltip title="Shows when the job post has been posted">
         <div className='flex flex-col w-fit border  border-gray-400 rounded-lg  align-middle p-3 text-center '>
          <p className='text-base font-bold'>Posted On</p>
          {moment(props.otherdata.postedOn).fromNow()}
         </div>
         </Tooltip>
         <Tooltip title="Shows the apply rate of the job post">
         <div className='flex flex-col w-fit border  border-gray-400 rounded-lg  align-middle p-3 text-center '>
          <p className='text-base font-bold'>ApplyRate</p>
          {calculateApplyRate(props.otherdata.clicked_apply,props.otherdata.clicked_viewed)}%
         </div>
         </Tooltip>
            </div>
         <p className='mb-4 mx-auto font-extrabold text-base  '>SHARE THIS JOB:</p>
         <div className=' p-2  rounded-lg mx-auto mb-4  w-full h-auto flex flex-row justify-evenly'>
         
         <div className='w-fit h-auto cursor-pointer mt-5 border  border-gray-400 rounded-lg  align-middle p-3 text-center' onClick={()=>handleXTweet()}><span><i class="fa-brands fa-x-twitter" style={{fontSize:'20px', color:'black'}}></i></span></div>

          {/* <div className='w-fit h-auto '><QRCodeSVG value={props.applylink}  bgColor='white' height={80} width={80} /></div> */}
          
          <div className='w-fit h-auto cursor-pointer mt-5 border  border-gray-400 rounded-lg  align-middle p-3 text-center' onClick={()=>handleLinkedInShare()}><span><i class="fab fa-linkedin-in" style={{fontSize:'20px', color:'black'}}></i></span></div>
          </div>
          <div className='bg-slate-50 border border-l-4  p-2  rounded-lg mx-auto mb-4  w-full h-auto flex flex-col justify-around'>
         
          <p className='mx-2 '><Text  className='text-xs font-thin'>👋	Please mention that you come from <strong>UIUX/JOBS HUB</strong> when applying for this job.</Text></p>
          <p className='mx-2 '><Text  className='text-xs font-thin'>📍	Do not spam apply. Be aware of the location restriction for a job position</Text></p>
          <p className='mx-2 '><Text  className='text-xs font-thin '> 🛑	Beware of scams!!!. When applying for jobs, you should NEVER have to pay anything</Text></p>
          </div>
          {/* </div> */}
          </>
  )
}
const handleLinkedInShare = () => {
  // Define the URL of the article or content you want to share
  const currentUrl = window.location.href;
  const articleUrl = 'https://uiuxjobsboard.com/job/818224-ux-researcher-berlin-germany';

  // Encode the URL for use in the LinkedIn share URL
  const encodedUrl = encodeURIComponent(currentUrl);

  // Construct the LinkedIn share URL
  const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
// https://www.linkedin.com/company/105351179/admin/page-posts/published/?share=true

  // Open a new window with the LinkedIn share URL
  window.open(linkedInShareUrl, '_blank');
}
const handleLinkedInShareByAdmin = () => {
  const currentUrl = window.location.href;
  const postText = `🎯 Outstanding ${props.selectedCategory} Role Available!

We're excited to share this amazing opportunity:

🏢 Company: ${props.companyName}
📋 Position: ${props.title}
💰 Compensation: ${props.selectedSalary}
📍 Location: ${props.selectedCountry}
⚡ Employment Type: ${props.selectedOptiontype}

Why This Role?
✨ Work on impactful projects
🌱 Great growth opportunities
🤝 Join an innovative team

Ready to take your career to the next level? Apply now:
${currentUrl}

#UIUXDesign #JobAlert #DesignJobs #Hiring`;

  navigator.clipboard.writeText(postText);
  window.open(`https://www.linkedin.com/company/105351179/admin/page-posts/published/?share=true`, '_blank');
}
function addBrAfterClosingTags(htmlContent) {
  // Use regex to find closing tags and add a <br> tag after each one
  return htmlContent.replace(/(<\/(?!li\b)\w+>)/g, '$1<br>');
}
 const handleActionBasedonCountry = async () => {
  await handleJobApplyCounter(props.jobid);
 // console.log('applylink',props.applylink);
  window.open(`${props.applylink}`, '_blank');
  // if(userCountry===props.selectedCountry||userCountry==='Not found'){
  //   window.open(`http://${props.companywebsiteLink}`, '_blank');
  // }else{
  //   // open modal
  //  Modal.error({
  //   title: 'Error',
  //   content: `This Job Post is not available in your country. \n Your country is ${userCountry}. Allowed country is ${props.selectedCountry}.`,
  //   okButtonProps: { style: { background: '#ff4d4f', borderColor: '#ff4d4f' } }
  //  })

  // }
  
 } 
  useEffect(() => {
   if(props.value){
    const parsedContent = addBrAfterClosingTags(props.value);
    setParsedContent(parsedContent);
   }
  },[props])

  const handleJobView = () => {
    console.log('id:-', props.jobid);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/api/update-viewed`, { // Adjust the domain as necessary
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ jobId: props.jobid })
    })
    .then(response => {
     
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('data:-=--=-', data);
    }).catch(error => {
      console.error('Fetch error:', error);
    });
  }

  useEffect(()=>{
    if(props.isRendered){
      handleJobView();
    }
  },[props.isRendered])

  const modules = {
    toolbar: false,
    
    }
    const handleJobApplyCounter = async (id) => {
      console.log('id:-', id);
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/api/update-clicked-apply`, { // Adjust the domain as necessary
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ jobId: id })
      })
      console.log('response:-', response);
      console.log('response.json()',response.json());
    }
 // console.log('applylink',props.applylink);
 // console.log('company',props.companywebsiteLink);
    return (
      <div className="max-w-4xl mx-auto bg-white shadow-xl rounded-lg overflow-hidden my-8">
        {/* Header Section */}
        <div className="p-8">
          <div className="flex items-center justify-between flex-wrap gap-4">
            <div className="flex items-center">
              <div className="relative">
                <img 
                  src={props.display_url} 
                  alt={props.companyName} 
                  className="h-20 w-20 rounded-lg object-contain border border-gray-100" 
                />
              </div>
              <div className="ml-6">
                <h1 className="text-3xl font-bold text-gray-900">{props.title}</h1>
                <div className="flex items-center mt-2">
                  <span 
                    className="text-gray-600 font-medium hover:text-blue-600 cursor-pointer transition-colors"
                    onClick={() => window.open(props.companywebsiteLink, '_blank')}
                  >
                    {props.companyName}
                  </span>
                  <span className="mx-2 text-gray-400">•</span>
                  <span className="text-gray-600">{props.selectedCountry}</span>
                </div>
              </div>
            </div>
            
            <div className="flex flex-wrap gap-2">
              <span className="px-4 py-2 rounded-full text-sm font-medium bg-blue-50 text-blue-700">
                {props.selectedOptiontype}
              </span>
              <span className="px-4 py-2 rounded-full text-sm font-medium bg-green-50 text-green-700">
                {props.selectedSalary}
              </span>
              <span className="px-4 py-2 rounded-full text-sm font-medium bg-purple-50 text-purple-700">
                {props.selectedCategory}
              </span>
            </div>
          </div>

          {/* Stats Section */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-8">
            <Tooltip title="Number of times this job post has been viewed">
              <div className="bg-gray-50 rounded-lg p-4 text-center">
                <p className="text-sm text-gray-600">Views</p>
                <p className="text-2xl font-bold text-gray-900">{props.otherdata?.clicked_viewed || 0}</p>
              </div>
            </Tooltip>
            
            <Tooltip title="Number of applications submitted for this position">
              <div className="bg-gray-50 rounded-lg p-4 text-center">
                <p className="text-sm text-gray-600">Applications</p>
                <p className="text-2xl font-bold text-gray-900">{props.otherdata?.clicked_apply || 0}</p>
              </div>
            </Tooltip>
            
            <Tooltip title="When this job was posted">
              <div className="bg-gray-50 rounded-lg p-4 text-center">
                <p className="text-sm text-gray-600">Posted</p>
                <p className="text-lg font-medium text-gray-900">{moment(props.otherdata?.postedOn).fromNow()}</p>
              </div>
            </Tooltip>
            
            <Tooltip title="Percentage of viewers who applied for this position">
              <div className="bg-gray-50 rounded-lg p-4 text-center">
                <p className="text-sm text-gray-600">Apply Rate</p>
                <p className="text-2xl font-bold text-gray-900">
                  {calculateApplyRate(props.otherdata?.clicked_apply, props.otherdata?.clicked_viewed)}%
                </p>
              </div>
            </Tooltip>
          </div>

          {/* Action Buttons */}
          <div className="mt-8 flex flex-col gap-4">
            <button 
              onClick={() => handleActionBasedonCountry()}
              className="w-full bg-blue-600 text-white px-8 py-3 rounded-lg font-medium 
                hover:bg-blue-700 transition-colors flex items-center justify-center gap-2"
            >
              Apply Now
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
            </button>

            <div className="flex flex-col items-center gap-2">
              <p className="text-sm text-gray-600 font-medium">Share this opportunity</p>
              <div className="flex gap-2">
                <button 
                  onClick={() => handleXTweet()}
                  className="p-3 rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors"
                  title="Share on X (Twitter)"
                >
                  <i className="fa-brands fa-x-twitter text-xl" />
                </button>
                <button 
                  onClick={() => handleLinkedInShare()}
                  className="p-3 rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors"
                  title="Share on LinkedIn"
                >
                  <i className="fab fa-linkedin-in text-xl" />
                </button>

                {isOpenedByAdmin && (
                  <button 
                  onClick={() => handleLinkedInShareByAdmin()}
                  className="p-3 rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors"
                  title="Share on LinkedIn"
                >
                  <i className="fab fa-linkedin-in text-xl" />
                </button>
                )}
              </div>
            </div>
          </div>

          {/* Job Description */}
          <div className="mt-8 prose max-w-none">
            <div className="bg-gray-50 rounded-lg p-6">
            <ReactQuill 
              value={props.value || ''} 
              readOnly={true}
              modules={modules}
              theme="snow"
              className="bg-white rounded-lg shadow-sm"
            />
            </div>
          </div>

          {/* Footer Notice */}
          <div className="mt-8 bg-blue-50 border-l-4 border-blue-500 p-4 rounded-lg">
            <div className="flex flex-col gap-2 text-sm text-blue-900">
              <p>👋 Please mention that you found this job on <strong>UIUX/JOBS HUB</strong></p>
              <p>📍 Please note location requirements before applying</p>
              <p>🛑 Never pay to apply. Report suspicious listings</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  

export default Preview;
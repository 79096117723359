import React, {useEffect} from 'react'
import Vector from '../images/new_uiuxlogo1.png';
import Footers from '../Components/Footers'
import { Link } from 'react-router-dom'

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      <div className="min-h-screen bg-gray-50">
        {/* Header Navigation */}
        <nav className="bg-white shadow-sm">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center h-16">
              <Link to="/" className="flex items-center">
                <img className="h-8 w-auto" src={Vector} alt="Logo" />
              </Link>
              <div className="flex space-x-8">
                <Link to="/terms" className="text-gray-600 hover:text-gray-900 text-sm font-medium">Terms</Link>
                <Link to="/privacy-policy" className="text-gray-600 hover:text-gray-900 text-sm font-medium">Privacy</Link>
                <Link to="/contact-us" className="text-gray-600 hover:text-gray-900 text-sm font-medium">Get in touch</Link>
              </div>
            </div>
          </div>
        </nav>

        {/* Main Content */}
        <main className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          {/* Title */}
          <h1 className="text-4xl font-bold text-gray-900 text-center mb-12">
            Contact Us
          </h1>

          {/* Contact Form Section */}
          <section className="bg-white rounded-lg shadow-sm p-8 max-w-2xl mx-auto">
            <form
              action="https://formsubmit.co/shubhankardev8@gmail.com"
              method="POST"
              id="contact-form"
              name="EmailForm"
              className="space-y-6"
            >
              <div>
                <label
                  htmlFor="input-name"
                  className="block text-gray-700 text-sm font-medium mb-2"
                >
                  Your Name
                </label>
                <input
                  id="input-name"
                  name="name"
                  type="text"
                  placeholder="Enter your name"
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-colors duration-200"
                />
              </div>

              <div>
                <label
                  htmlFor="input-email"
                  className="block text-gray-700 text-sm font-medium mb-2"
                >
                  Your Email
                </label>
                <input
                  id="input-email"
                  name="input-email"
                  type="email"
                  required
                  placeholder="Enter your email"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-colors duration-200"
                />
              </div>

              <div>
                <label
                  htmlFor="input-message"
                  className="block text-gray-700 text-sm font-medium mb-2"
                >
                  Message
                </label>
                <textarea
                  id="input-message"
                  name="input-message"
                  rows="5"
                  placeholder="Enter your message"
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-colors duration-200"
                ></textarea>
              </div>

              <div>
                <button
                  className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-md transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  type="submit"
                  name="_next"
                  value="http://localhost:3000/contact-us"
                >
                  Send Message
                </button>
              </div>
            </form>
          </section>
        </main>

        <Footers />
      </div>
    </React.Fragment>
  )
}

export default Contact
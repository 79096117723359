import React, { useEffect, useState } from 'react';
import Vector from '../images/new_uiuxlogo1.png';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const BannerHome = () => {
  const [linkname, setlinkname] = useState('');
  const [avatars, setAvatars] = useState([]);

  const categories = [
    { id: 1, name: 'UI/UX Design', icon: '🎨' },
    { id: 2, name: 'UX Design', icon: '🔍' },
    { id: 3, name: 'UI Design', icon: '✨' },
    { id: 4, name: 'Product Design', icon: '💡' },
    { id: 5, name: 'UX Research', icon: '📊' },
    { id: 6, name: 'UI Research', icon: '🎯' }
  ];

  useEffect(() => {
    const currentURL = window.location.href;
    const parts = currentURL.split('/');
    const lastPart = parts[parts.length - 1];
    judger(lastPart);
  }, []);

  const judger = (judgement) => {
    setlinkname(judgement);
  };
  useEffect(() => {
    const fetchAvatars = async () => {
      try {
        const response = await fetch('https://randomuser.me/api/?results=5')
        const data = await response.json()
        setAvatars(data.results.map(user => user.picture.medium))
      } catch (error) {
        console.error('Error fetching avatars:', error)
        // Fallback to empty circles if API fails
        setAvatars([])
      }
    }

    fetchAvatars()
  }, [])
  return (
    <div className="relative bg-gradient-to-b from-gray-50 to-white overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 sm:py-8 lg:py-12">
        <div className="relative">
          {/* Main Content */}
          <div className="grid lg:grid-cols-2 gap-8 lg:gap-12 items-center">
            <motion.div 
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              className="space-y-6 sm:space-y-8"
            >
              <h1 className="text-3xl sm:text-5xl lg:text-6xl font-bold text-gray-900 leading-tight">
                Find Your Dream
                <span className="block bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
                  Design Career
                </span>
              </h1>
              <p className="text-base sm:text-lg lg:text-xl text-gray-600 max-w-2xl leading-relaxed">
                Connect with top companies hiring UI/UX designers, researchers, and product designers. New opportunities added daily.
              </p>
              
              <div className="flex flex-col sm:flex-row gap-4">
                {linkname !== 'list-seeker' && (
                  <motion.div 
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                    className="w-full sm:w-auto"
                  >
                    <Link to="/hire" className="block w-full sm:w-auto">
                      <button className="w-full sm:w-auto bg-blue-600 text-white px-6 sm:px-8 py-3 sm:py-4 rounded-full font-semibold 
                        shadow-lg hover:bg-blue-700 transition-all duration-300 flex items-center justify-center space-x-2
                        hover:scale-105 transform">
                        <span>Post a Job</span>
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                        </svg>
                      </button>
                    </Link>
                  </motion.div>
                )}
                {/* <Link to="/browse-jobs" className="block w-full sm:w-auto">
                  <button className="w-full sm:w-auto bg-white text-blue-600 px-6 sm:px-8 py-3 sm:py-4 rounded-full font-semibold 
                    shadow-lg hover:bg-blue-50 border-2 border-blue-600 transition-all duration-300
                    hover:scale-105 transform">
                    Browse Jobs
                  </button>
                </Link> */}
              </div>

              <div className="flex items-center gap-4 text-gray-600">
      <div className="flex -space-x-2">
        {avatars.length > 0 ? (
          avatars.map((avatar, i) => (
            <img
              key={i}
              src={avatar}
              alt={`User ${i + 1}`}
              className="w-6 h-6 sm:w-8 sm:h-8 rounded-full border-2 border-white object-cover"
            />
          ))
        ) : (
          // Fallback to gray circles if no avatars
          [1, 2, 3].map((i) => (
            <div
              key={i}
              className="w-6 h-6 sm:w-8 sm:h-8 rounded-full bg-gray-200 border-2 border-white"
            />
          ))
        )}
      </div>
      <p className="text-xs sm:text-sm">Join 10,000+ designers who found their next role here</p>
    </div>
            </motion.div>

            <motion.div 
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              className="relative mt-8  lg:mt-0 hidden lg:block"
            >
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
                {categories.map((category) => (
                  <motion.div
                    key={category.id}
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.2, delay: category.id * 0.1 }}
                    className="group cursor-pointer"
                  >
                    <div className="bg-white p-4 sm:p-6 rounded-xl sm:rounded-2xl shadow-md border border-gray-100 
                      hover:shadow-xl hover:border-blue-100 transition-all duration-300
                      transform hover:-translate-y-1">
                      <div className="text-xl sm:text-2xl mb-2">{category.icon}</div>
                      <p className="text-sm sm:text-base text-gray-800 font-medium group-hover:text-blue-600 transition-colors">
                        {category.name}
                      </p>
                    </div>
                  </motion.div>
                ))}
              </div>

              {/* Decorative elements - hidden on smaller screens */}
              <div className="hidden sm:block absolute -right-4 -bottom-4 w-24 h-24 bg-blue-50 rounded-full filter blur-xl opacity-70" />
              <div className="hidden sm:block absolute -left-4 -top-4 w-20 h-20 bg-purple-50 rounded-full filter blur-xl opacity-70" />
            </motion.div>
          </div>
        </div>
      </div>

      {/* Subtle wave decoration */}
      <div className="absolute bottom-0 left-0 right-0 h-16 sm:h-20 bg-gradient-to-b from-transparent to-white" />
    </div>
  );
};

export default BannerHome;
import React, {useEffect} from 'react'
import Vector from '../images/new_uiuxlogo1.png';
import Footers from '../Components/Footers'
import { Link } from 'react-router-dom'

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      <div className="min-h-screen bg-gray-50">
        {/* Header Navigation */}
        <nav className="bg-white shadow-sm">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center h-16">
              <Link to="/" className="flex items-center">
                <img className="h-8 w-auto" src={Vector} alt="Logo" />
              </Link>
              <div className="flex space-x-8">
                <Link to="/terms" className="text-gray-600 hover:text-gray-900 text-sm font-medium">Terms</Link>
                <Link to="/privacy-policy" className="text-gray-600 hover:text-gray-900 text-sm font-medium">Privacy</Link>
                <Link to="/contact-us" className="text-gray-600 hover:text-gray-900 text-sm font-medium">Get in touch</Link>
              </div>
            </div>
          </div>
        </nav>

        {/* Main Content */}
        <main className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          {/* Title */}
          <h1 className="text-4xl font-bold text-gray-900 text-center mb-12">
            Privacy Policy
          </h1>

          {/* Content Sections */}
          <div className="space-y-8">
            {/* Overview Section */}
            <section className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Overview</h2>
              <p className="text-gray-600 leading-relaxed">
                Your privacy is important to us. It is UIUXjobshub's policy to respect your privacy and comply with any applicable law and regulation regarding any personal information we may collect about you, including across our website, https://UIUXjobshub.com, and other sites we own and operate. This policy is effective as of 26 November 2018 and was last updated on 7 September 2021.
              </p>
            </section>

            {/* Children's Privacy Section */}
            <section className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Children's Privacy</h2>
              <p className="text-gray-600 leading-relaxed">
                We do not aim any of our products or services directly at children under the age of 13, and we do not knowingly collect personal information about children under 13.
              </p>
            </section>

            {/* International Transfers Section */}
            <section className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">International Transfers of Personal Information</h2>
              <p className="text-gray-600 leading-relaxed">
                The personal information we collect is stored and/or processed where we or our partners, affiliates, and third-party providers maintain facilities. Please be aware that the locations to which we store, process, or transfer your personal information may not have the same data protection laws as the country in which you initially provided the information. If we transfer your personal information to third parties in other countries: (i) we will perform those transfers in accordance with the requirements of applicable law; and (ii) we will protect the transferred personal information in accordance with this privacy policy.
              </p>
            </section>

            {/* Your Rights Section */}
            <section className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Your Rights and Controlling Your Personal Information</h2>
              <div className="space-y-4 text-gray-600 leading-relaxed">
                <p>You always retain the right to withhold personal information from us, with the understanding that your experience of our website may be affected. We will not discriminate against you for exercising any of your rights over your personal information.</p>
                <p>If you do provide us with personal information you understand that we will collect, hold, use and disclose it in accordance with this privacy policy. You retain the right to request details of any personal information we hold about you.</p>
                <p>If we receive personal information about you from a third party, we will protect it as set out in this privacy policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person's consent to provide the personal information to us.</p>
                <p>If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time. We will provide you with the ability to unsubscribe from our email-database or opt out of communications. Please be aware we may need to request specific information from you to help us confirm your identity.</p>
              </div>
            </section>

            {/* Cookies Section */}
            <section className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Use of Cookies</h2>
              <p className="text-gray-600 leading-relaxed">
                We use "cookies" to collect information about you and your activity across our site. A cookie is a small piece of data that our website stores on your computer, and accesses each time you visit, so we can understand how you use our site. This helps us serve you content based on preferences you have specified.
              </p>
            </section>

            {/* Policy Limits Section */}
            <section className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Limits of Our Policy</h2>
              <p className="text-gray-600 leading-relaxed">
                Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of those sites, and cannot accept responsibility or liability for their respective privacy practices.
              </p>
            </section>

            {/* Policy Changes Section */}
            <section className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Changes to This Policy</h2>
              <p className="text-gray-600 leading-relaxed">
                At our discretion, we may change our privacy policy to reflect updates to our business processes, current acceptable practices, or legislative or regulatory changes. If we decide to change this privacy policy, we will post the changes here at the same link by which you are accessing this privacy policy. If required by law, we will get your permission or give you the opportunity to opt in to or opt out of, as applicable, any new uses of your personal information.
              </p>
            </section>

            {/* Contact Section */}
            <section className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Contact Us</h2>
              <p className="text-gray-600 leading-relaxed">
                For any questions or concerns regarding about the Terms of Service, you may contact us using the following details: <a href="mailto:hello@UIUXjobshub.com" className="text-blue-600 hover:text-blue-800">hello@UIUXjobshub.com</a>
              </p>
            </section>
          </div>
        </main>

        <Footers />
      </div>
    </React.Fragment>
  )
}

export default Privacy
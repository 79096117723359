import React,{useEffect, useState} from 'react'
import AdvertiseCard from '../Components/AdvertiseCard';
import Select from 'react-select';
//import Footers from '../Components/Footers';
import Footers from '../Components/Footers';
import BannerHome from '../Components/BannerHome';
import { country } from '../values/constants';
import { JobCardData } from '../values/constants';
import { Link } from 'react-router-dom';
import {fetchAllJobsAsync} from '../GlobalState/job-slice';
import { useSelector, useDispatch } from 'react-redux';
import { Flex, Spin, Skeleton, Empty, Typography } from 'antd';
import { Helmet } from 'react-helmet-async';
import { Seo } from './Seo';
import amazon from '../images/amazon.png';
import angellist from '../images/angellist.png';
import github from '../images/github.png';
import indeed from '../images/indeed.png';
import shopify from '../images/shopify.png';
import stripe from '../images/stripe.png';
import upwork from '../images/upwork.png';

const HomePage = () => {
    const [list, setList] = useState([]);
    const TrustCompanies = [1, 2, 3, 4, 5, 6, 7 ];
    const [loading, setLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [selectedJobType, setSelectedJobType] = useState(null);

    const categories = [
      { value: 'UI/UX Design', label: 'UI/UX Design 🎨' },
      { value: 'UI Design', label: 'UI Design 🖌️' },
      { value: 'UX Design', label: 'UX Design 🎯' },
      { value: 'Product Design', label: 'Product Design ⚡' },
      { value: 'UX Research', label: 'UX Research 🔍' },
      { value: 'UX Writing', label: 'UX Writing ✍️' },
    ];
// Add this to your constants
const jobTypes = [
  { value: 'fulltime', label: 'Full-time 👨‍💼' },
  { value: 'contract', label: 'Contract 📄' },
  { value: 'internship', label: 'Internship 🎓' }
];
    const dispatch = useDispatch();
    const fetchAllJobs = async() => {
     const response =  await dispatch(fetchAllJobsAsync());
     setLoading(false);
    }

    const allJobs = useSelector((state) => state.jobs.jobs);
    const imageSource = (number) => {
      switch (number) {
        case 1:
          return amazon;
        case 2:
          return angellist;
        case 3:
          return github;
        case 4:
          return indeed;
        case 5:
          return shopify;
        case 6:
          return stripe;
        case 7:
          return upwork;
        default:
          return;
      }
    };
    useEffect(()=>{
      window.scrollTo(0, 0);
      if (!allJobs|| allJobs.length === 0) {
        setLoading(true);
        fetchAllJobs();
      }
    },[]);

//allJobs, dispatch
    // const getAllJobList = () => {
     
    //   fetch("${process.env.REACT_APP_BACKEND_URL}/v1/api/all-jobs", {
    //     method:"GET",
    //     headers:{
    //       "Content-Type":"application/json"
    //     },
    //     mode:"cors",
    //   })
    //   .then((res) => {
    //     if (res && res.ok){
    //       return res.json(); 
         
    //     }else {
    //       throw new Error("Network response was not ok.");
    //     }
    //   }).then((data) => {
    //    // console.log('inside res');
    //    // console.log('data:', data);
    //    // console.log('message:', data.message.results);
    //     setList(data.message.results); // Set the list using the correct data property
    //   })
    //   .catch(e => {
    //     console.log(e.error);
    //    // setList([...JobCardData]);
    //   })
    // }
    const handleFilter = () => {
      let filtered = [...allJobs];
      
      if (selectedCategory) {
        filtered = filtered.filter(job => job.selectedCategory === selectedCategory.value);
      }
      
      if (selectedCountry) {
        filtered = filtered.filter(job => job.selectedCountry === selectedCountry.value);
      }
      if (selectedJobType) {
        filtered = filtered.filter(job => job.selectedOptiontype === selectedJobType.value);
      }
      
      setFilteredJobs(filtered);
    };
    useEffect(() => {
      handleFilter();
    }, [selectedCategory, selectedCountry, selectedJobType, allJobs]);

  return (
    <React.Fragment>
      
    <div className=' h-auto pt-1'>
      <Seo
        title="UI/UX Jobs Hub - Find UI/UX Design and Research Jobs"
        description="Browse our daily updated job board and find great UI/UX design jobs, product design jobs and UX researcher jobs at startups and large companies."
        type="WebSite"
        name="UI/UX JobsHub"
      />
      {/* <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="title" content="UI/UX JobsHub"></meta>
                <meta name="description" content="Browse our daily updated job board and find great UI/UX design jobs, product design jobs and UX researcher jobs at startups and large companies."></meta>
               <meta name="keywords" content="UI/UX design jobs, Product design jobs, UX researcher jobs, Startups, Large companies, Daily updated job board, Design job board, UI/UX job listings, UX research positions, Product designer roles, Design careers,jobs,jobBoard"></meta>
                <link rel="icon" href="/favicon.ico" />
      </Helmet> */}
        <div className=' h-auto md:h-80 p-3 w-100 rounded-xl '>
            <BannerHome/>
        </div>
          
          



        <div className='flex justify-center items-center gap-4 md:gap-6 lg:mt-40 md:mt-32 mt-24 mb-10'>
          <span className='text-sm font-light text-gray-500  mr-5 md:block hidden'>Trusted by:</span>
        {TrustCompanies.map((item, key) => (
            <img 
              src={imageSource(item)}
              style={{  height: '2.5vw', 
                WebkitFilter: 'grayscale(100%)',
               }}
              alt={key}
            />
          ))}
        </div>

          {/* here is the filter */}
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
  <div className="bg-white rounded-2xl shadow-lg p-6 mb-8">
    <div className="flex items-center mb-4">
      <span className="text-2xl mr-2">🎯</span>
      <h2 className="text-xl font-semibold text-gray-900">Filter Jobs</h2>
    </div>
    
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          <span className="flex items-center">
            <span className="mr-2">🎨</span>
            Category
          </span>
        </label>
        <Select
          value={selectedCategory}
          onChange={setSelectedCategory}
          options={categories}
          isClearable
          placeholder="Select category..."
          className="basic-select"
        />
      </div>
      
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          <span className="flex items-center">
            <span className="mr-2">🌍</span>
            Location
          </span>
        </label>
        <Select
          value={selectedCountry}
          onChange={setSelectedCountry}
          options={country}
          isClearable
          placeholder="Select location..."
          className="basic-select"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          <span className="flex items-center">
            <span className="mr-2">💼</span>
            Job Type
          </span>
        </label>
        <Select
          value={selectedJobType}
          onChange={setSelectedJobType}
          options={jobTypes}
          isClearable
          placeholder="Select job type..."
          className="basic-select"
        />
      </div>
    </div>

    {/* Optional: Show active filters */}
    {(selectedCategory || selectedCountry || selectedJobType) && (
      <div className="mt-4 flex flex-wrap gap-2">
        {selectedCategory && (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
            {selectedCategory.label}
          </span>
        )}
        {selectedCountry && (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
            {selectedCountry.label}
          </span>
        )}
        {selectedJobType && (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-purple-100 text-purple-800">
            {selectedJobType.label}
          </span>
        )}
      </div>
    )}
  </div>
</div>

          {/* here is the filter */}

        {/* this is where we render the cards */}
        <div className='flex flex-col mx-3'>
        <Spin size="large" spinning={loading} fullscreen />
        {
          loading && (<><Skeleton className='w-full md:w-4/6 mx-auto' active /><Skeleton className='w-full md:w-4/6 mx-auto' active /><Skeleton className='w-full md:w-4/6 mx-auto' active /></>) 
        }
        {
  filteredJobs.length > 0 ? (
    filteredJobs.map((ele) => (
      <Link to={`/summary?id=${ele.id}`} key={ele.id}>
        <AdvertiseCard props={ele} />
      </Link>
    ))
  ) : (
    !loading && (
      <Empty 
        description={
          <Typography.Text strong>
            No jobs found matching your filters <span className='text-blue-500'>🔍</span>
          </Typography.Text>
        } 
        className='w-full md:w-4/6 mx-auto mb-5'
      />
    )
  )
}
        </div>   
        <Footers/>
    </div>
    </React.Fragment>
  )
}

export default HomePage;